@use 'assets/styles/utils/helper' as h;

.bookmarkButton {
  --bookmark-button-margin: #{h.rem(20px) 0 h.rem(18px) h.rem(-14px)};
  --background-ribbon-left: auto;
  --background-ribbon-right: #{h.rem(-6px)};
  --background-ribbon-width: #{h.rem(1000px)};
  --background-ribbon-border-top: #{h.rem(53px) solid var(--accent-purple-light)};
  --background-ribbon-border-right: #{h.rem(10px) solid transparent};

  @include h.breakpoint-l {
    --bookmark-button-margin: #{h.rem(24px) 0 h.rem(26px) h.rem(-14px)};
  }
}

:global(.videoPage) .bookmarkButton {
  --bookmark-button-margin: #{h.rem(6px) 0 0 h.rem(-12px)};
  --background-ribbon-border-top: #{h.rem(53px) solid var(--secondary-70)};

  @include h.breakpoint-xx {
    --background-ribbon-right: #{h.rem(-2px)};
    --background-ribbon-width: #{h.rem(50px)};
  }
}

:global(.articlePage) .bookmarkButton,
:global(.liveBlogPage) .bookmarkButton {
  --bookmark-button-margin: #{h.rem(20px) 0 h.rem(35px) h.rem(-14px)};

  @include h.breakpoint-x {
    --background-ribbon-right: #{h.rem(-2px)};
    --background-ribbon-width: #{h.rem(50px)};
  }
}

:global(.recipePage) .bookmarkButton {
  @include h.breakpoint-x {
    --background-ribbon-right: #{h.rem(-2px)};
    --background-ribbon-width: #{h.rem(50px)};
  }
}

:global(body.today.videoPage) .bookmarkButton {
  --bookmark-button-margin: #{h.rem(16px) h.rem(-20px) h.rem(16px) h.rem(-14px)};
  --background-ribbon-left: 0;
  --background-ribbon-right: auto;
  --background-ribbon-width: #{h.rem(175px)};
  --background-ribbon-border-top: #{h.rem(60px) solid var(--secondary-70)};
  --background-ribbon-border-right: none;
  --message-white-space: normal;
  --message-font-size: #{h.rem(12px)};
  --message-width: #{h.rem(100px)};

  @include h.breakpoint-m {
    --bookmark-button-margin: #{h.rem(16px) h.rem(-30px) h.rem(16px) h.rem(-14px)};
  }

  @include h.breakpoint-l {
    --bookmark-button-margin: #{h.rem(20px) 0 0 h.rem(-12px)};
    --background-ribbon-left: auto;
    --background-ribbon-right: #{h.rem(-2px)};
    --background-ribbon-width: #{h.rem(1000px)};
    --background-ribbon-border-top: #{h.rem(53px) solid var(--secondary-70)};
    --background-ribbon-border-right: #{h.rem(10px) solid transparent};
    --message-white-space: pre-line;
    --message-font-size: #{h.rem(14px)};
    --message-width: auto;
  }

  @include h.breakpoint-xx {
    --background-ribbon-width: #{h.rem(50px)};
    --background-ribbon-border-top: #{h.rem(53px) solid var(--secondary-70)};
  }
}
