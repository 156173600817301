@use 'assets/styles/utils/helper' as h;

:root {
  --live-flag--position: absolute;
  --live-red-color: #cb1111;
  --live-stroke-color: #cb1111;
  --live-flag--left: 20px;
  --live-flag--top: 20px;
  --live-flag--dot-container--margin-right: -15px;
  --live-flag--dot--background-color: var(--white);
  --live-flag--margin: none;

  // Label
  --live-flag--text--background-color: var(--default-color);
  --live-flag--text--border-color: var(--default-color);
  --live-flag--text--color: var(--white);
  --live-flag--breaking--label--background-color: var(--red-40);
  --live-flag--breaking--label--text--color: var(--white);
  --live-flag--text--font-family: var(--founders-cond);
  --live-flag--text--font-size: var(--text-12);
  --live-flag--text--height: var(--space-28); // 32px with 4px stripe border
  --live-flag--text--padding: 0 10px;
  --live-flag--text--margin: initial;
  --live-flag--text--offset-padding: 0;
  --live-flag--text--line-height: var(--leading-100);
  --live-flag--text-transform: none;
  --live-flag--text-box-shadow: none;

  // Timestamp
  --live-flag--timestamp--background-color: var(--white);
  --live-flag--timestamp--color: var(--default-color);
  --live-flag--timestamp--font-family: inherit;
  --live-flag--timestamp--font-size: inherit;
  --live-flag--timestamp--font-weight: normal;
  --live-flag--timestamp--margin-left: auto;
  --live-flag--timestamp--padding: 0 10px;
  --live-flag--timestamp--line-height: var(--leading-100);

  // Stripe pattern
  --live-flag--stripe--stroke-color: var(--default-color);
  --live-flag--stripe--display: block;

  // Active state
  --live-flag--active--text--background-color: var(--live-red-color);
  --live-flag--active--text--border-color: var(--live-red-color);
  --live-flag--active--text--color: var(--white);
  --live-flag--active--label--background-color: var(--live-red-color);
  --live-flag--active--timestamp--background-color: var(--white);
  --live-flag--active--timestamp--color: var(--live-red-color);
  --live-flag--active--stripe--stroke-color: var(--live-red-color);

  @include h.breakpoint-m {
    --live-flag--top: 25px;
    --live-flag--left: 0;
  }

  @include h.breakpoint-l {
    --live-flag--left: 20px;
    --live-flag--text--font-size: var(--text-16);
    --live-flag--text--height: var(--space-36); // 42px with 6px stripe border
    --live-flag--text--offset-padding: 0 0 2px 0; // account for white space around founders-cond
  }

  @include h.breakpoint-x {
    --live-flag--left: 220px;
  }
}

body.msnbc {
  --live-flag--text--background-color: var(--grey-70);
  --live-flag--text--border-color: var(--grey-70);
  --live-flag--timestamp--color: var(--grey-70);
  --live-flag--stripe--stroke-color: var(--grey-70);
}

body.noticias,
body.shows {
  --live-flag--text--background-color: var(--blue-40);
  --live-flag--text--border-color: var(--blue-40);
  --live-flag--timestamp--color: var(--blue-40);
  --live-flag--stripe--stroke-color: var(--blue-40);
  --live-flag--active--text--background-color: var(--red-40);
  --live-flag--active--text--border-color: var(--red-40);
  --live-flag--active--timestamp--color: var(--red-40);
  --live-flag--active--stripe--stroke-color: var(--red-40);
  --live-flag--active--label--background-color: var(--red-40);
  --live-flag--breaking--label--background-color: var(--red-40);
}

// Select's css vars for colors are inconsistent between the dev and production builds
// Temporarily hard-coding the values
body.select {
  // Label
  --live-flag--text--background-color: #bababa;
  --live-flag--text--border-color: #bababa;
  --live-flag--text--color: #2a2a2a;
  --live-flag--text--font-family: var(--lato);
  --live-flag--text--font-size: 11px;
  --live-flag--text--font-weight: 900;
  --live-flag--text--offset-padding: 0;

  // Timestamp
  --live-flag--timestamp--background-color: var(--white);
  --live-flag--timestamp--color: var(--live-flag--text--color);
  --live-flag--timestamp--font-family: var(--lato);
  --live-flag--timestamp--font-weight: 900;

  // Stripe pattern
  --live-flag--stripe--stroke-color: #bababa;

  // Active state
  --live-flag--active--text--background-color: #0477c9;
  --live-flag--active--text--border-color: #74c5ff;
  --live-flag--active--text--color: var(--white);
  --live-flag--active--timestamp--background-color: var(--white);
  --live-flag--active--timestamp--color: #0477c9;
  --live-flag--active--stripe--stroke-color: #74c5ff;
  --live-flag--active--label--background-color: #0477c9;

  @include h.breakpoint-l {
    --live-flag--text--font-size: 14px;
    --live-flag--text--offset-padding: 0;
    --live-flag--timestamp--font-size: 14px;
  }
}

body.today {
  --live-flag--dot--background-color: var(--news-red-30);
  --live-flag--dot-container--margin-right: -28px;
  --live-flag--left: auto;
  // Label
  --live-flag--text--background-color: var(--grey-70);
  --live-flag--text--color: var(--grey-20);
  --live-flag--text--border-color: none;
  --live-flag--text--font-family: var(--secondary-font);
  --live-flag--text--font-size: var(--text-16);
  --live-flag--text--font-weight: 700;
  --live-flag--text--offset-padding: 0;
  --live-flag--text--padding: #{h.rem(0 18px)};
  --live-flag--text--height: #{h.rem(40px)};
  --live-flag--text--line-height: #{h.rem(40px)};
  --live-flag--text-transform: skewX(-15deg);
  --live-flag--text-label-transform: skewX(15deg);
  --live-flag--text-box-shadow: -4px -4px #ffb186;
  --live-flag--before--content: 0;

  .live-flag--active {
    --live-flag--dot-container--margin-right: -15px;
    --live-flag--text--padding: #{h.rem(0 18px 0 28px)};
    --live-flag--text--margin: #{h.rem(0 0 0 -15px)};
  }

  // Timestamp
  --live-flag--timestamp--color: var(--grey-70);
  --live-flag--timestamp--font-size: var(--text-14);
  --live-flag--timestamp--background-color: var(--white);
  --live-flag--timestamp--font-family: var(--secondary-font);
  --live-flag--timestamp--margin-left: -5px;
  --live-flag--timestamp--padding: 0 16px;
  --live-flag--timestamp--line-height: 1.5;

  // Stripe pattern
  --live-flag--stripe--display: none;

  // Active state
  --live-flag--active--text--background-color: var(--grey-70);
  --live-flag--active--text--border-color: none;
  --live-flag--active--label--background-color: transparent;
  --live-flag--active--timestamp--color: var(--grey-70);
  --live-flag--active--stripe--stroke-color: none;
  --live-flag--active--timestamp--background-color: none;

  // Breaking state
  --live-red-color: var(--grey-70);
  --live-red-breaking-label-color: var(--white);
  --live-stroke-color: none;
  --live-flag--breaking--label--background-color: var(--grey-20);
  --live-flag--breaking--label--text--color: var(--grey-70);
}

body.today .article-duet-layout--heading {
  --live-flag--position: static;
  --live-flag--margin: 0 0 20px 5px;

  // label
  --live-flag--text--background-color: var(--grey-70);

  // Timestamp
  --live-flag--timestamp--background-color: none;
}

.article-hero__container--breaking {
  --live-flag--default--color: var(--live-red-color);
  --live-flag--default--background-color: var(--live-red-color);
}
