@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.article-hero-headline {
  padding: var(--article-hero-headline--padding);
}

.article-hero-headline--live-breaking {
  padding: var(--article-hero-headline--live--padding);
}

.article-hero-headline__htag {
  color: var(--article-hero-headline--htag--color);
  font-size: var(--article-hero-headline--htag--font-size);
  font-family: var(--article-hero-headline--htag--font-family);
  font-weight: var(--article-hero-headline--htag--font-weight);
  line-height: var(--article-hero-headline--htag--line-height);
  margin: var(--article-hero-headline--htag--margin);
}

// Breaking template has a special unibrow appearing only on breakpoint l
.article-hero-headline.article-hero-headline---breaking:not(.article-hero-headline__recipe-hero) {
  padding-top: 0;

  .unibrow.articleTitleSection.article-hero__tax-term {
    display: none;

    @include h.breakpoint-l {
      display: block;
    }
  }
}

.article-hero-headline__timestamp {
  @include h.responsive-variations(font-size, t.$article-hero-headline-timestamp-font-size);
  color: var(--article-hero-headline-timestamp-color);
  font-family: t.$article-hero-headline-timestamp-font-family;
  font-style: normal;
  letter-spacing: 0;
  text-align: left;
  line-height: t.$article-hero-headline-timestamp-line-height;
  margin-top: 12px;
  margin-bottom: 20px;
}

.article-hero-headline__timestamp,
.article-hero__flag__updated {
  font-weight: 600;
}

.article-hero-headline__recipe-hero {
  padding-top: h.rem(4px);
  margin-bottom: h.rem(-24px);
}

.article-hero-headline--recipe {
  @include h.responsive-variations(padding, t.$article-hero-headline--recipe--padding);
}

.article-hero-headline--recipe .article-hero-headline__htag {
  font-size: var(--article-hero-headline--recipe--htag--font-size);
  line-height: var(--article-hero-headline--recipe--htag--line-height);
  margin-bottom: var(--article-hero-headline--recipe--htag--margin-bottom);

  @include h.responsive-variations(font-weight, t.$article-hero-headline--recipe--htag--font-weight);
}

.article-hero-headline__htag--live-breaking {
  font-size: var(--article-hero-headline--live--htag--font-size);
  line-height: var(--article-hero-headline--live--htag--line-height);
}
