@use 'assets/styles/utils/helper' as h;

.sponsoredWrap {
  font-family: var(--founders-mono);
  font-weight: normal;
  font-size: h.rem(12px);
  line-height: h.rem(15px);
  margin: h.rem(20px) h.$outer-gutter h.rem(20px) h.$outer-gutter;

  @include h.breakpoint-l {
    margin-left: unset;
    margin-right: unset;
  }

  a {
    color: var(--default-color);

    &:hover {
      color: var(--grey-50);
    }

    :global(body.news) & {
      color: var(--blue-70);
    }

    :global(body.noticias) & {
      color: var(--blue-60);
    }

    :global(body.news) &:hover,
    :global(body.noticias) &:hover {
      color: var(--grey-50);
    }
  }
}

.sponsoredInner {
  &::before {
    font-family: var(--founders-mono);
    font-weight: normal;
    content: 'PAID CONTENT';
    display: block;
    color: var(--grey-70);
    font-size: h.rem(12px);
    line-height: 2;
  }
}

.sponsoredTop {
  border-top: solid h.rem(2px) var(--grey-30);
  border-bottom: solid h.rem(2px) var(--grey-30);
  display: flex;
  align-items: center;
}

.nbcSponsoredLogo,
.sponsoredLogo {
  flex-basis: h.rem(40px);

  @include h.breakpoint-m {
    flex-basis: h.rem(70px);
  }
}

.nbcSponsoredLogo {
  padding: h.rem(15px 10px);

  img {
    width: h.rem(30px);

    @include h.breakpoint-m {
      width: h.rem(40px);
    }
  }
}

.sponsoredLogo {
  padding: h.rem(10px 10px 10px 0);

  img {
    height: h.rem(30px);

    @include h.breakpoint-m {
      height: h.rem(40px);
    }
  }
}

.sponsoredLabel {
  padding: h.rem(18px 0 18px 20px);
  flex-basis: h.rem(130px);
  flex-grow: 1;
}

.sponsoredMore {
  .sponsoredTop {
    border-bottom: none;
  }

  /* stylelint-disable no-descending-specificity */
  .sponsoredLabel,
  .sponsoredLabel a {
    color: var(--grey-40);
  }
}

.moreInfo {
  font-family: var(--publico-txt);
  font-weight: normal;
  font-size: h.rem(12px);
  line-height: h.rem(21px);
  color: var(--grey-60);
  padding: h.rem(0 0 15px);
  border-bottom: solid h.rem(2px) var(--grey-30);
}
