@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.disclaimer-container {
  height: 100%;

  @include h.breakpoint-s-only {
    min-height: 58px;
  }

  @include h.breakpoint-m {
    min-height: 61px;
  }
}

.article-hero__container {
  position: relative;
  z-index: h.get-z-index('article-hero');

  &::before {
    content: '';
    display: block;
    height: var(--article-hero-top-border-height);
    background-color: var(--article-hero-top-border-bg-color);
    background-image: var(--article-hero-top-border-bg-img);
    position: absolute;
    right: 0;
    z-index: 2;
    width: calc(100% - 20px);

    @include h.breakpoint-l {
      width: calc(920px + ((100% - 920px) / 2));
    }

    @include h.breakpoint-x {
      width: calc(1160px + ((100% - 1160px) / 2));
    }

    .chromeless {
      display: none;
    }
  }

  .article-hero__divider {
    display: var(--article-hero-divider-display);
    height: var(--article-hero-top-border-height);
    background-color: var(--article-hero-top-border-bg-color);
    background-image: var(--article-hero-top-border-bg-img);
    z-index: 2;
    width: calc(100% - 20px);
    float: right;

    @include h.breakpoint-l {
      width: calc(920px + ((100% - 920px) / 2));
    }

    @include h.breakpoint-x {
      width: calc(1160px + ((100% - 1160px) / 2));
    }

    .chromeless {
      display: none;
    }
  }

  .article-hero__header {
    @include h.clearfix;
    padding: var(--article-hero--header--padding);
    position: relative;
    z-index: 1;
    clear: both;

    // aka "flagged", previous version of the LiveBlog this el was called a "tag"
    &.article-hero__is-tagged {
      padding-top: h.rem(51px);
      padding-bottom: 0;

      @include h.breakpoint-m {
        padding-top: h.rem(57px);
        padding-bottom: var(--article-hero-header--flagged--padding-bottom, h.rem(80px));
      }

      @include h.breakpoint-l {
        padding-top: h.rem(66px);
        padding-bottom: var(--article-hero-header--flagged--padding-bottom, h.rem(100px));
      }
    }

    &.article-hero__is-breaking--wide {
      background-color: var(--article-page-background);

      @include h.breakpoint-m {
        margin-top: h.rem(-47px);
      }
    }
  }

  .article-hero__bg-container {
    position: relative;

    &::before {
      content: '';
      background-color: var(--article-hero-header-bg-color);
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100vw;

      @media print {
        width: 100%;
      }
    }
  }

  .article-hero__unibrow-grid.article-hero__ecommerce-enabled {
    margin-top: h.rem(35px);

    .article-hero__live-blog {
      margin-top: h.rem(55px);
    }
  }

  .article-hero__flag {
    &-border {
      width: fit-content;
      padding: 0 h.rem(4px) h.rem(4px) 0;
      background-image:
        linear-gradient(
          135deg,
          var(--article-hero-flag-border-color) 25%,
          transparent 25%,
          transparent 50%,
          var(--article-hero-flag-border-color) 50%,
          var(--article-hero-flag-border-color) 75%,
          transparent 75%,
          transparent 100%
        );
      background-size: 5.66px 5.66px;
    }

    &-container {
      @include h.responsive-variations(font-size, t.$article-flag-text-font-size);
      display: flex;
      align-items: center;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include h.breakpoint-l {
        height: 36px;
      }
      height: 28px;
      width: fit-content;
      font-family: var(--founders-cond);
      font-weight: 600;
      color: var(--white);
      background: var(--article-hero-flag-text-bg-color);
    }
  }

  .article-hero__tax-term {
    margin: var(--article-hero--taxonomy-term--margin);
    font-family: var(--article-hero--taxonomy-term--font-family);
    font-size: var(--article-hero--taxonomy-term--font-size);
    font-weight: var(--article-hero--taxonomy-term--font-weight);
    letter-spacing: var(--article-hero--taxonomy-term--letter-spacing);
    text-transform: var(--article-hero--taxonomy-term--text-transform);
    color: var(--article-hero--taxonomy-term--color);
    position: relative;
    z-index: 3;

    &.article-hero__enhanced-opinion {
      font-size: h.rem(24px);
      font-family: var(--founders-cond);
      font-weight: 600;
      text-transform: none;
    }
  }

  .article-hero__featured {
    display: inline-block;
    margin-right: h.rem(5px);

    @include h.breakpoint-m {
      margin-right: h.rem(10px);
    }

    body.think & {
      font-family: var(--founders-cond);
      font-size: h.rem(32px);
      line-height: 1.25;
      font-weight: 300;

      @include h.breakpoint-m {
        font-size: h.rem(48px);
      }
    }
  }

  &.article-hero__live-blog,
  &.article-hero__container--breaking {
    &::before {
      @include h.breakpoint-x {
        width: calc(100% - ((100% - 760px) / 2));
      }
    }
  }

  &.article-hero__container--breaking::before {
    background-color: var(--article-hero-container-breaking-before-bg-color);
  }

  body.today &.article-hero__container--breaking::before {
    background-color: var(--article-hero-container-breaking-before-line-color);
  }
}

.article-hero__media-holder {
  pointer-events: none;
  position: relative;
  z-index: 1;

  &:not(:empty) {
    margin-bottom: var(--article-hero--media-holder--margin-bottom);
  }
}

.article-hero__media-holder--compact {
  &:not(:empty) {
    margin-bottom: var(--space-32);
  }
}

.article-hero__media-container {
  pointer-events: all;
}

.article-hero__caption {
  margin-top: var(--article-hero--caption--margin-top);
  padding-left: t.$article-hero-outer-gutter-width-mobile;
  padding-right: t.$article-hero-outer-gutter-width-mobile;

  @include h.breakpoint-m {
    padding-left: 0;
    padding-right: 0;
  }
}

.caption.article-hero__caption--cvsdk {
  font-family: var(--founders-cond);
  font-size: h.rem(24px);
  line-height: 24px;

  @include h.breakpoint-s-only {
    font-size: h.rem(16px);
    line-height: 16px;
  }

  &::before {
    content: none;
  }
}

.caption.article-hero__caption--recipe {
  margin-top: 0;
}

.article-hero__sponsor {
  width: 100%;
}

.spread {
  .article-hero__media-holder {
    @include h.breakpoint-l {
      float: right;
      width: calc(100% - ((100% - 920px) / 2));
      margin-top: h.rem(-175px);
    }

    @include h.breakpoint-x {
      width: calc(100% - ((100% - 1160px) / 2));
    }
  }

  .article-hero__main {
    @include h.breakpoint-l {
      position: relative;
    }

    &::before {
      @include h.breakpoint-l {
        @include h.pattern-dots(var(--white));
        content: '';
        display: block;
        width: h.rem(80px);
        height: h.rem(40px);
        position: relative;
        left: -2px;
        top: -2px;
      }
    }

    &::after {
      @include h.breakpoint-l {
        content: '';
        background-color: var(--article-hero-spread-hero-bg-color);
        position: absolute;
        z-index: -1;
        width: 100%;
        height: h.rem(65px);
        top: h.rem(113px);
        left: calc(-100% + 920px);
      }

      @include h.breakpoint-x {
        height: h.rem(80px);
        left: calc(-100% + 1160px);
      }
    }

    .article-hero__main-image {
      &::before {
        @include h.breakpoint-l {
          content: '';
          background-color: var(--article-hero-spread-hero-main-image-bg-color);
          position: absolute;
          left: h.rem(120px);
          height: h.rem(80px);
          width: calc(100% - 120px);
        }

        @include h.breakpoint-x {
          width: calc(100% - 100px);
          left: h.rem(100px);
        }
      }
    }
  }
}

.article-hero__presentation-wide {
  display: flex;
  flex-direction: column;

  .article-hero__bg-container {
    order: 1;
    z-index: 10;
  }

  .article-hero__media-holder {
    margin-bottom: 0;

    &::after,
    &::before {
      content: '';
      width: 100%;
      height: var(--article-hero--wide--media-holder--height);
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &::before {
      backdrop-filter: var(--article-hero--wide--media-holder--filter);
      mask-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1));
    }

    &::after {
      background: var(--article-hero--wide--media-holder-after--background);
      filter: var(--article-hero--wide--media-holder--filter);
    }
  }

  .article-hero__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: var(--article-hero--wide--main--max-height);
    overflow: hidden;
  }

  .article-hero__main-image img {
    height: auto;
  }

  .article-hero__caption {
    width: 100%;
    position: absolute;
    bottom: var(--article-hero--wide--caption--bottom);
    left: 0;
    text-align: var(--article-hero--wide--caption--text-align);
    font-family: var(--secondary-font);
    font-size: h.rem(12px);
    color: var(--white);
    margin-left: 0;
    z-index: 1;

    .caption__source {
      color: var(--white);
    }
  }

  .article-hero__header {
    margin-top: var(--article-hero--wide--header--margin-top);
    text-align: var(--article-hero--wide--header--text-align);
  }

  .article-hero__unibrow-grid {
    max-width: h.rem(940px);
    padding-top: h.rem(30px);
    background-color: var(--white);
    margin: auto;
  }
}

.liveBlog {
  .article-hero__header.article-hero__is-tagged {
    .article-hero__unibrow-grid {
      @include h.responsive-variations(
        margin-top,
        t.$article-hero--tagged-header--unibrow-margin-top
      );
      @include h.responsive-variations(
        padding-top,
        t.$article-hero--tagged-header--unibrow-padding-top
      );
    }
  }
}

.default {
  .article-hero__media-container {
    @include h.clearfix;
  }

  .article-hero__main {
    .article-hero__main-image img {
      height: auto;
      width: 100%;
    }
  }

  .article-hero__main,
  .article-hero__video {
    @include h.breakpoint-m {
      width: 100%;
      margin-left: 0;
    }
    margin-top: var(--article-hero--image--default--margin-top);

    .article-hero__video-outer {
      position: relative;

      &::before {
        content: '';
        display: block;
        padding-top: 56.25%;
      }

      .article-hero__video-inner {
        background-color: var(--black);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .article-hero__video {
    aspect-ratio: 16/9;
  }

  .article-hero__video__subheader-large {
    margin-bottom: h.rem(62px);
  }

  .article-hero__video__subheader-small {
    @include h.breakpoint-m-max {
      margin-bottom: h.rem(42px);
    }
  }

  .article-hero__header {
    padding: var(--article-hero--header--default--padding);
  }
}

.none {
  .article-hero__header {
    padding: var(--article-hero--header--none--padding);

    // Only adjusts padding bottom when no-art (.none). Inherit the other default is-tagged padding.
    &.article-hero__header.article-hero__is-tagged {
      @include h.responsive-variations(
        padding-bottom,
        t.$article-hero--header--none--tagged--padding-bottom
      );
    }
  }

  & .article-hero__shopping-section {
    padding-bottom: 0;
  }
}

.article-hero__sticky-video {
  position: relative;
  // use sticky-video-hero-parent so in liveblog
  // sticky video doesn't go under article video content
  z-index: h.get-z-index('sticky-video-hero-parent');
}

.article-hero__container.article-hero__live-breaking::before {
  @include h.responsive-variations(top, t.$article-hero-top-live-border-top);
}

.article-hero__container.article-hero__live-blog::before {
  content: var(--live-flag--before--content, '');
  background-color: var(--live-flag--text--border-color);
  background-image: none;
  height: 1px;

  .liveBlog--active & {
    background-color: var(--live-flag--active--text--border-color);
  }
}

.article-hero__container--breaking .article-hero__tax-term {
  margin-top: 0;
}
