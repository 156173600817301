@use 'assets/styles/utils/helper' as h;

$article-hero-top-live-border-top: (s: h.rem(20px), m: h.rem(24px)) !default;
$article-hero-outer-gutter-width-mobile: 20px !default;

$article-hero--header--none--tagged--padding-bottom: (l: h.rem(47px), m: h.rem(0)) !default;

$article-hero--tagged-header--unibrow-margin-top: (s: h.rem(2px), l: h.rem(30px), x: h.rem(10px)) !default;
$article-hero--tagged-header--unibrow-padding-top: (s: h.rem(8px), l: h.rem(12px), x: h.rem(48px)) !default;

$article-flag-text-font-size: (s: var(--text-12), l: var(--text-16)) !default;

:root {
  --article-hero-top-border-top: #{h.rem(20px)};
  --article-hero-top-border-bg-color: rgba(255, 255, 255, 0.3);
  --article-hero-top-border-bg-img: none;
  --article-hero-top-border-height: 1px;
  --article-hero-divider-display: block;
  --article-hero-header-bg-top-padding: initial;
  --article-hero--header--padding: #{h.rem(45px 20px 40px)};
  --article-hero--header--default--padding: var(--article-hero--header--padding);
  --article-hero--header--none--padding: var(--article-hero--header--padding);
  --article-hero-header-bg-color: var(--default-color);
  --article-hero-spread-hero-bg-color: var(--default-color);
  --article-hero-spread-hero-main-image-bg-color: var(--default-color);
  --article-hero-flag-border-color: #{h.$breaking-red};
  --article-hero-flag-text-bg-color: #{h.$breaking-red};
  --article-hero-container-breaking-before-bg-color: var(--red-40);
  --article-hero--taxonomy-term--color: var(--white);
  --article-hero--taxonomy-term--font-family: var(--founders-mono);
  --article-hero--taxonomy-term--font-size: var(--text-12);
  --article-hero--taxonomy-term--font-weight: 400;
  --article-hero--taxonomy-term--letter-spacing: -0.04em;
  --article-hero--taxonomy-term--text-transform: uppercase;
  --article-hero--taxonomy-term--margin: 0;
  --article-hero--image--default--margin-top: 0;
  --article-hero--caption--margin-top: #{h.rem(16px)};
  --article-hero--media-holder--margin-bottom: var(--space-48);

  @include h.breakpoint-m {
    --article-hero-top-border-top: #{h.rem(40px)};
    --article-hero--header--padding: #{h.rem(60px 0)};
    --article-hero--header--default--padding: #{h.rem(60px 0 112px)};
    --article-hero--image--default--margin-top: #{h.rem(-80px)};
  }

  @include h.breakpoint-l {
    --article-hero--header--padding: #{h.rem(60px 0 110px)};
    --article-hero--header--default--padding: #{h.rem(90px 0 118px)};
    --article-hero--header--none--padding: #{h.rem(60px 0 47px)};
    --article-hero--image--default--margin-top: #{h.rem(-100px)};
  }

  @include h.breakpoint-x {
    --article-hero--header--padding: #{h.rem(100px 0)};
    --article-hero--taxonomy-term--margin: #{h.rem(10px)} 0 0;
    --article-hero--header--default--padding: #{h.rem(100px)} 0;
    --article-hero--header--none--padding: #{h.rem(80px 0 60px)};
  }
}

body.news {
  --article-hero-header-bg-color: var(--blue-70);
  --article-hero-spread-hero-bg-color: var(--blue-70);
  --article-hero-spread-hero-main-image-bg-color: var(--blue-70);
}

body.today {
  --article-hero-top-border-height: 2px;
  --article-hero-divider-display: none;
  --article-hero-spread-hero-bg-color: var(--white);
  --article-hero-spread-hero-main-image-bg-color: var(--white);
  --article-hero-header-bg-color: var(--white);
  --article-hero--taxonomy-term--color: var(--grey-70);
  --article-hero--taxonomy-term--font-family: var(--secondary-font);
  --article-hero--taxonomy-term--font-size: var(--text-12);
  --article-hero--taxonomy-term--font-weight: 700;
  --article-hero--taxonomy-term--letter-spacing: normal;
  --article-hero-container-breaking-before-bg-color: var(--grey-20);
  --article-hero-container-breaking-before-text-color: var(--grey-70);
  --article-hero-container-breaking-before-line-color: none;
  --article-hero-header-bg-top-padding: 1.5rem;
  --article-hero--header--padding: #{h.rem(40px 20px 20px)};
  --article-hero--header--default--padding: #{h.rem(30px 20px 20px)};
  --article-hero--header--none--padding: #{h.rem(40px 20px 0)};
  --article-hero-header--flagged--padding-bottom: 0;
  --article-hero--image--default--margin-top: 0;
  --article-hero--caption--margin-top: 0;
  --article-hero--media-holder--margin-bottom: #{h.rem(40px)};
  --article-hero-top-border-bg-color: transparent;
  --article-hero-top-border-bg-img: none;

  &.liveBlogPage {
    --article-hero-container-breaking-before-bg-color: var(--grey-70);
    --article-hero-container-breaking-before-text-color: var(--white);
  }

  @include h.breakpoint-m {
    --article-hero--header--padding: #{h.rem(40px 0)};
    --article-hero--header--default--padding: #{h.rem(40px 0 20px)};
    --article-hero--header--none--padding: #{h.rem(40px 0 0)};
    --article-hero--caption--margin-top: #{h.rem(6px)};
  }

  @include h.breakpoint-l {
    --article-hero-header-bg-top-padding: 2.5rem;
    --article-hero--media-holder--margin-bottom: #{h.rem(55px)};
  }

  @include h.breakpoint-x {
    --article-hero--header--padding: #{h.rem(40px 0 40px 0)};
    --article-hero--header--default--padding: #{h.rem(40px 0 20px 0)};
    --article-hero--header--none--padding: #{h.rem(40px 0 0 0)};
  }

  .article-hero__presentation-wide {
    --article-hero--header--default--padding: #{h.rem(40px 0)};
    --article-hero--wide--main--max-height: #{h.rem(375px)};
    --article-hero--wide--header--margin-top: 0;
    --article-hero--wide--caption--bottom: #{h.rem(12px)};
    --article-hero--wide--caption--text-align: left;
    --article-hero--header--padding: #{h.rem(0 20px 40px)};
    --article-hero--wide--header--text-align: left;
    --article-hero--wide--media-holder--height: #{h.rem(85px)};
    --article-hero--wide--media-holder-after--background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, .4) 100%);
    --article-hero--wide--media-holder--filter: blur(5px);

    @include h.breakpoint-m {
      --article-hero--wide--main--max-height: #{h.rem(380px)};
      --article-hero--wide--header--margin-top: #{h.rem(-90px)};
      --article-hero--wide--caption--bottom: #{h.rem(60px)};
      --article-hero--wide--caption--text-align: center;
      --article-hero--header--padding: #{h.rem(40px 0 40px 0)};
      --article-hero--wide--header--text-align: center;
      --article-hero--wide--media-holder--height: #{h.rem(100px)};
    }

    @include h.breakpoint-l {
      --article-hero--wide--main--max-height: #{h.rem(500px)};
      --article-hero--wide--media-holder--height: #{h.rem(95px)};
      --article-hero--wide--media-holder--filter: blur(10px);
      --article-hero--wide--media-holder-after--background: radial-gradient(ellipse at 50% 200%, rgba(0, 0, 0, .45) 0%, rgba(0, 0, 0, 0) 100%);
    }

    @include h.breakpoint-x {
      --article-hero--wide--main--max-height: #{h.rem(620px)};
    }

    @include h.breakpoint-xx {
      --article-hero--wide--main--max-height: #{h.rem(650px)};
    }
  }
}

body.today.recipePage {
  --article-hero--media-holder--margin-bottom: #{h.rem(10px)};
}

body.deportes,
body.noticias {
  --article-hero-header-bg-color: var(--blue-60);
  --article-hero-spread-hero-bg-color: var(--blue-60);
  --article-hero-spread-hero-main-image-bg-color: var(--blue-60);
}

body.telemundo {
  --article-hero-top-border-bg-color: var(--red-50);
  --article-hero-header-bg-color: var(--neutral-30);
  --article-hero--taxonomy-term--color: var(--red-50);
  --article-hero-spread-hero-bg-color: var(--blue-60);
  --article-hero-spread-hero-main-image-bg-color: var(--blue-60);
  --article-hero-flag-border-color: var(--red-40);
  --article-hero-flag-text-bg-color: var(--red-40);
  --article-hero-container-breaking-before-bg-color: var(--red-40);
  --article-hero-container-breaking-before-line-color: var(--red-40);
  --live-red-color: var(--red-40);
}

body.entretenimiento {
  --article-hero-top-border-bg-color: var(--red-50);
  --article-hero-header-bg-color: var(--neutral-30);
  --article-hero--taxonomy-term--color: var(--red-50);
  --article-hero-spread-hero-bg-color: var(--blue-60);
  --article-hero-spread-hero-main-image-bg-color: var(--blue-60);
  --live-red-color: var(--red-40);
}

body.shows {
  --article-hero-top-border-bg-color: var(--red-50);
  --article-hero-header-bg-color: var(--neutral-30);
  --article-hero--taxonomy-term--color: var(--red-50);
  --article-hero-spread-hero-bg-color: var(--blue-60);
  --article-hero-spread-hero-main-image-bg-color: var(--blue-60);
  --live-red-color: var(--red-40);
}

body.select {
  --article-hero-top-border-height: 0;
  --article-hero-top-border-bg-color: #999;
  --article-hero-header-bg-color: var(--white);
  --article-hero--taxonomy-term--color: var(--blue-60);
  --article-hero--taxonomy-term--font-family: var(--lato);
  --article-hero--header--padding: #{h.rem(32px 20px 28px)};
  --article-hero--header--default--padding: #{h.rem(32px 20px 28px)};
  --article-hero--header--none--padding: var(--article-hero--header--padding);

  @include h.breakpoint-m {
    --article-hero-top-border-top: #{h.rem(32px)};
    --article-hero--header--padding: #{h.rem(28px 0 60px)};
    --article-hero--header--default--padding: #{h.rem(28px 0 104px)};
  }

  @include h.breakpoint-l {
    --article-hero--header--padding: #{h.rem(48px 0 110px)};
    --article-hero--header--default--padding: #{h.rem(48px 0 124px)};
    --article-hero--header--none--padding: #{h.rem(48px 0 47px)};
  }

  @include h.breakpoint-x {
    --article-hero--header--padding: #{h.rem(48px 0 110px)};
    --article-hero--taxonomy-term--margin: #{h.rem(8px)} 0 0;
    --article-hero--header--none--padding: #{h.rem(48px 0 60px)};
  }
}

body.msnbc {
  --article-hero-header-bg-color: #{h.$msnbc-default-color};
}
