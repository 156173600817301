@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.live-flag {
  display: inline-block;
  left: var(--live-flag--left);
  position: var(--live-flag--position);
  margin: var(--live-flag--margin);
  top: var(--live-flag--top);
  z-index: 3;

  &__dot {
    background-color: var(--live-flag--dot--background-color);
    margin-right: var(--space-8);
  }

  &__container {
    align-items: stretch;
    color: var(--live-flag--text--color);
    display: flex;
    font-family: var(--live-flag--text--font-family);
    font-size: var(--live-flag--text--font-size);
    font-weight: var(--live-flag--text--font-weight);
    height: var(--live-flag--text--height);
    line-height: var(--live-flag--text--line-height);
    text-transform: uppercase;
  }

  &__label {
    align-items: center;
    display: flex;
    margin: var(--live-flag--text--margin);
    padding: var(--live-flag--text--padding);
    background-color: var(--live-flag--text--background-color);
    transform: var(--live-flag--text-transform);
    box-shadow: var(--live-flag--text-box-shadow);

    &-text {
      padding: var(--live-flag--text--offset-padding);
      transform: var(--live-flag--text-label-transform);
      text-wrap: nowrap;
    }
  }

  &__timestamp {
    align-items: center;
    background-color: var(--live-flag--timestamp--background-color);
    color: var(--live-flag--timestamp--color);
    display: flex;
    font-family: var(--live-flag--timestamp--font-family);
    font-size: var(--live-flag--timestamp--font-size);
    font-weight: var(--live-flag--timestamp--font-weight);
    line-height: var(--live-flag--timestamp--line-height);
    padding: var(--live-flag--timestamp--padding);
    text-transform: none;
    margin-left: var(--live-flag--timestamp--margin-left);
  }

  .stripe-pattern {
    --stripe-width: 4px;
    display: var(--live-flag--stripe--display);
    position: absolute;
    top: 0;
    z-index: -1;
    height: calc(100% + var(--stripe-width));
    width: calc(100% + var(--stripe-width));

    @include h.breakpoint-l {
      --stripe-width: 6px;
    }

    line {
      stroke: var(--live-flag--stripe--stroke-color);
    }
  }

  &--active {
    .live-flag__container {
      background-color: var(--live-flag--active--label--background-color);
      color: var(--live-flag--active--text--color);

      .live-flag__dot-container {
        align-items: center;
        display: flex;
        padding-left: 15px;
        margin-right: var(--live-flag--dot-container--margin-right);
        z-index: 1;
      }
    }

    .live-flag__label {
      text-align: left;
      background-color: var(--live-flag--active--text--background-color);
    }

    .live-flag__timestamp {
      background: var(--live-flag--active--timestamp--background-color);
      color: var(--live-flag--active--timestamp--color);
    }

    .stripe-pattern line {
      stroke: var(--live-flag--active--stripe--stroke-color);
    }
  }

  &.live-flag--breaking {
    .live-flag__label {
      background-color: var(--live-flag--breaking--label--background-color);
      color: var(--live-flag--breaking--label--text--color);
    }

    .live-flag__timestamp {
      color: var(--live-red-color);
    }

    .stripe-pattern {
      line {
        stroke: var(--live-stroke-color);
      }
    }

    .article-hero__is-breaking--wide & {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      //to account for the parent element margin shifting everything over
      margin-left: h.rem(-20px);

      @include h.breakpoint-m {
        margin-left: 0;
      }
    }
  }

  &--wide {
    @include h.breakpoint-l {
      padding: 0 20px;
    }
  }
}
