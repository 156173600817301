@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss' as t;

.bookmarkButton {
  display: flex;
  align-items: center;
  gap: h.rem(16px);
  margin: var(--bookmark-button-margin);
}

.bookmarkButtonWrapper {
  position: relative;
  width: h.rem(44px);
  height: h.rem(53px);
  display: flex;
  align-items: center;
}

.backgroundRibbon {
  position: absolute;
  left: var(--background-ribbon-left);
  right: var(--background-ribbon-right);
  height: 0;
  width: var(--background-ribbon-width);
  border-top: var(--background-ribbon-border-top);
  border-right: var(--background-ribbon-border-right);
  background-color: transparent;
  opacity: 0;

  &.shouldShow {
    opacity: 1;
  }
}

.message {
  white-space: pre-line;
  color: var(--secondary-color);
  margin: 0;
  padding: 0;
  font-family: var(--secondary-font);
  font-size: h.rem(14px);
  font-weight: 700;
  line-height: h.rem(17.5px);
}

// Styles for Video Page
:global(.videoPage) {
  .message {
    color: var(--white);
  }

  .backgroundRibbon {
    // New Ribbon for xx breakpoint
    @include h.breakpoint-xx {
      height: h.rem(50px);
      transform: skew(-10deg, 0);
      border: none;
      background-color: var(--secondary-70);
    }
  }
}

// Styles for Article Page
:global(.articlePage),
:global(.liveBlogPage) {
  .backgroundRibbon {
    @include h.breakpoint-x {
      height: h.rem(50px);
      transform: skew(-10deg, 0);
      border: none;
      background-color: var(--accent-purple-light);
    }
  }
}

.myNewsLink {
  text-decoration: underline;
  display: block;
}

// Styles for Recipe Page
:global(.recipePage) .backgroundRibbon {
  @include h.breakpoint-x {
    height: h.rem(50px);
    transform: skew(-10deg, 0);
    border: none;
    background-color: var(--accent-purple-light);
  }
}

:global(body.today.videoPage) {
  .backgroundRibbon {
    border-left: 0.625rem solid transparent;
  }

  .message {
    position: relative;
    white-space: var(--message-white-space);
    font-size: var(--message-font-size);
    width: var(--message-width);
    line-height: 1.25;
  }
}
