@use 'assets/styles/utils/helper' as h;

$article-hero-headline--recipe--padding: (s: 20px 0 0, m: 16px 0 0) !default;

$article-hero-headline--recipe--htag--font-weight: 300 !default;

$article-hero-headline-timestamp-font-family: var(--founders-cond) !default;
$article-hero-headline-timestamp-font-size: (s: h.rem(18px), m: h.rem(20px)) !default;
$article-hero-headline-timestamp-line-height: var(--leading-100) !default;

:root {
  --article-hero-headline--padding: #{h.rem(12px)} 0 0;
  --article-hero-headline--live--padding: #{h.rem(14px)} 0 0;
  --article-hero-headline--htag--color: var(--white);
  --article-hero-headline--htag--font-size: 2rem;
  --article-hero-headline--htag--font-family: var(--founders-cond);
  --article-hero-headline--htag--font-weight: 300;
  --article-hero-headline--htag--line-height: 1;
  --article-hero-headline--htag--margin: 0 0 #{h.rem(12px)} 0;
  --article-hero-headline--live--htag--line-height: 1;
  --article-hero-headline--live--htag--font-size: 2rem;
  --article-hero-headline-timestamp-color: var(--white);
  --article-hero-headline-updated-border-color: #{h.$breaking-red};
  --article-hero-headline-updated-color: #{h.$breaking-red};
  --article-hero-headline--recipe--htag--font-size: 1.5rem;
  --article-hero-headline--recipe--htag--line-height: 1.25;
  --article-hero-headline--recipe--htag--margin-bottom: 12px;

  @include h.breakpoint-m {
    --article-hero-headline--live--padding: #{h.rem(12px)} 0 0;
    --article-hero-headline--htag--font-size: 3rem;
    --article-hero-headline--live--htag--font-size: 2.5rem;
    --article-hero-headline--recipe--htag--font-size: 3rem;
    --article-hero-headline--recipe--htag--line-height: 1;
    --article-hero-headline--recipe--htag--margin-bottom: 15px;
  }

  @include h.breakpoint-l {
    --article-hero-headline--padding: #{h.rem(40px)} 0 0;
  }

  @include h.breakpoint-x {
    --article-hero-headline--padding: 0;
    --article-hero-headline--live--padding: #{h.rem(10px)} 0 0 #{h.rem(200px)};
    --article-hero-headline--htag--font-size: 3.75rem;
    --article-hero-headline--live--htag--font-size: 3rem;
    --article-hero-headline--recipe--htag--font-size: 3.75rem;
  }
}

body.think {
  --article-hero-headline--htag--font-family: var(--publico-hed);
  --article-hero-headline--htag--line-height: 1.25;

  @include h.breakpoint-m {
    --article-hero-headline--htag--font-size: 3rem;
  }
}

body.today {
  --article-hero-headline--live--padding: #{h.rem(40px)} 0 0;
  --article-hero-headline-timestamp-color: var(--purple-70);
  --article-hero-headline--recipe--htag--line-height: 1.5;
  --article-hero-header-bg-top-padding: 0;
  --article-hero-headline--padding: #{h.rem(10px)} 0 0;
  --article-hero-headline--htag--font-family: var(--primary-font);
  --article-hero-headline--htag--color: var(--grey-70);
  --article-hero-headline--htag--font-weight: normal;
  --article-hero-headline--htag--line-height: 1.5;
  --article-hero-headline--htag--margin: 0 0 #{h.rem(10px)} 0;
  --article-hero-headline--htag--font-size: #{h.rem(24px)};
  --article-hero-headline--live--htag--font-size: #{h.rem(24px)};
  --article-hero-headline--live--htag--line-height: 1.5;
  --article-hero-headline--recipe--htag--font-size: #{h.rem(32px)};

  @include h.breakpoint-m {
    --article-hero-headline--live--padding: #{h.rem(46px)} 0 0;
    --article-hero-headline--padding: #{h.rem(12px)} 0 0;
    --article-hero-headline--htag--font-size: #{h.rem(40px)};
    --article-hero-headline--htag--margin: 0 0 #{h.rem(20px)} 0;
    --article-hero-headline--live--htag--font-size: #{h.rem(48px)};
    --article-hero-headline--recipe--htag--font-size: #{h.rem(40px)};
  }

  @include h.breakpoint-l {
    --article-hero-headline--htag--font-size: #{h.rem(48px)};
  }

  .article-hero__presentation-wide {
    --article-hero-headline--htag--font-size: #{h.rem(24px)};
    --article-hero-headline--htag--margin: 0;

    @include h.breakpoint-m {
      --article-hero-headline--htag--font-size: #{h.rem(35px)};
    }
  }
}

body.telemundo {
  --article-hero-headline--htag--color: var(--blue-60);
  --article-hero-headline-updated-color: #{h.$noticias-red-50};
  --article-hero-headline-updated-border-color: #{h.$noticias-red-50};
}

body.entretenimiento {
  --article-hero-headline--htag--color: var(--blue-60);
  --article-hero-headline-timestamp-color: #{h.$noticias-red-50};
}

body.shows {
  --article-hero-headline--htag--color: var(--blue-60);
  --article-hero-headline-timestamp-color: #{h.$noticias-red-50};
}

body.select {
  --article-hero-headline--htag--color: #2a2a2a;
  --article-hero-headline--htag--font-size: 28px;
  --article-hero-headline--htag--font-family: var(--lato);
  --article-hero-headline--htag--font-weight: 900;
  --article-hero-headline--htag--line-height: 1.1;
  --article-hero-headline--htag--margin: 0 0 #{h.rem(12px)} 0;
  --article-hero-headline--live--htag--line-height: 36px;
  --article-hero-headline--live--htag--font-size: 30px;

  @include h.breakpoint-m {
    --article-hero-headline--live--htag--line-height: 43px;
    --article-hero-headline--htag--font-size: 40px;
    --article-hero-headline--live--htag--font-size: 36px;
  }

  @include h.breakpoint-l {
    --article-hero-headline--padding: #{h.rem(16px)} 0 0;
    --article-hero-headline--htag--font-size: 46px;
  }

  @include h.breakpoint-x {
    --article-hero-headline--padding: 0;
    --article-hero-headline--live--htag--line-height: 50px;
    --article-hero-headline--live--htag--font-size: 42px;
  }
}
